import { useEffect } from 'react'
import xIcon from '../../assets/x.svg'
import '../../styles/popUp.css'

const PopUp = ({ content, isVisible, toggleIsVisible }) => {
  const outsideClickListener = e => {
    if(!e.target.closest('.container')){
      toggleIsVisible()
    }
  }
  return (
    <div 
      onClick={outsideClickListener}
      className="pop-up"
      style={{display: isVisible ? 'flex' : 'none'}}
    >
      <div className="container">
        <img 
          onClick={e => {
            e.stopPropagation()
            toggleIsVisible()
          }}
          className='x' 
          src={xIcon} 
        />
        {content}
      </div>
    </div>
  );
}
 
export default PopUp;