import '../styles/login.css'
import loginLogo from '../assets/login-logo.svg'
import userLogo from '../assets/user-logo.svg'
import lockLogo from '../assets/lock-logo.svg'
import PopUp from './reusable/PopUp'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useToggleState from './hooks/useToggleState'

const Login = () => {
  const navigate = useNavigate()
  const [errorIsVisible, setErrorIsVisible] = useState(false)
  
  const [isVisible, toggleIsVisible] = useToggleState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const popUpContent = (
    <div className="content">
      <h2>Cambiar Contrase&ntilde;a</h2>
      <p>Para cambiar su contraseña, favor contactar a <a target='_blank' href="mailto:soporte@aereopuerto.com">Soporte</a>.</p>
    </div>
  )

  const validateLogin = () => {
    if(username == 'admin' && password == 'admin'){
      navigate('gestion-roles')
    }else {
      setErrorIsVisible(true)
    }
  }

  return (
    <div className="login">
      <PopUp 
        isVisible={isVisible} 
        toggleIsVisible={toggleIsVisible}
        content={popUpContent} 
      />
      <form className="login-form">
        <img 
          src={loginLogo}
          className='login-logo'
        />

        <div className="input-icon-wrapper username">
          <img src={userLogo}/>
          <input 
            type="text" 
            placeholder="Usuario"
            onChange={e => setUsername(e.target.value)}
            value={username}
          />
        </div>

        <div className="input-icon-wrapper username">
          <img src={lockLogo}/>
          <input 
            type="password" 
            placeholder="Contrase&ntilde;a"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <span 
          style={{marginBottom: '20px', color: 'red', display: errorIsVisible ? 'inline' : 'none'}}
        >Credenciales Incorrectos</span>

        <button
          onClick={e => {
            validateLogin()
            e.preventDefault()
          }}
        >Ingresar</button>

        <Link
          onClick={toggleIsVisible}
          className='change-password'
          to='/#'
        >Cambiar Contrase&ntilde;a</Link>
      </form>
      <div className="create-account">
        <span>A&uacute;n no tienes cuenta? <Link className='register' to='/registrarse'>Registrarme</Link></span>
      </div>
    </div>
  );
}
 
export default Login;