import { useEffect, useState } from "react";

const DatosNomina = () => {
  let selectedData = {
    username: '',
    name: '',
  }
  let selectedLabels = {
    username: 'Usuario',
    name: 'Nombre',
  }
  const [populatedElement, setPopulatedElement] = useState(<></>)

  const updateRenderedData = () => {
    let elementArr = []
    
    for(let att in selectedData){
      let element = (
        <div style={{marginBottom: '30px'}}>
          <span style={{fontWeight: 700, marginRight: '20px'}}>{ selectedLabels[att] }:</span>
            <input 
              type="text"
              placeholder={selectedData[att]}
            />
        </div>
      )
      elementArr.push(element)
    }

    setPopulatedElement(
      <div className="data-table">
        {elementArr.map(element => element)}
      </div>
    )
  }

  useEffect(() => {
    updateRenderedData()
  }, [selectedData])

  return (
    <div className="datos-empleado">
      {console.log(selectedData)}
      {selectedData && populatedElement}
    </div> 
  );
}
 
export default DatosNomina;