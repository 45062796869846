import navBtn from '../assets/nav-btn.svg'
import xIcon from '../assets/x-white.svg'
import useToggleState from './hooks/useToggleState'
import userImg from '../assets/user-img.png'
import logoutIcon from '../assets/logout-icon.svg'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

const Nav = () => {
  const linkList = [
    { name: 'Gestión de Roles', url: 'gestion-roles', key: 0 },
    { name: 'Mapa de Puertos y Pistas', url: 'mapa-puertos-pistas', key: 1 },
    { name: 'Gestión de Puertos y Pistas', url: 'gestion-puertos-pistas', key: 2 },
    { name: 'Gestión de Vuelos', url: 'gestion-vuelos', key: 3 },
    { name: 'Registro y Gestión de Aerolíneas', url: 'registro-gestion-aerolineas', key: 4 },
    { name: 'Gestión de Pasajeros', url: 'gestion-pasajeros', key: 5 },
    { name: 'Gestión de Empleados', url: 'gestion-empleados', key: 6 },
  ];
  

  const location = useLocation();
  const [isVisible, toggleIsVisible] = useToggleState(false)
  const [displayStyle, setDisplayStyle] = useState('none');
  const [opacityStyle, setOpacityStyle] = useState('0');
  const [isLogged, setIsLogged] = useState(false);

  const checkForOutsideClick = e => {
    if(isVisible && e.target.classList.contains('dark-bg')){
      toggleIsVisible()
    }
  }

  const isActive = link => {
    let isActive = `/${link.url}` === location.pathname

    return isActive
  }

  useEffect(() => {
    let timeout1
    let timeout2
    if (isVisible) {
      setDisplayStyle('block');
      timeout1 = setTimeout(() => {
        setOpacityStyle(1)
      }, 50);    
    } else {
      setOpacityStyle(0)
      timeout2 = setTimeout(() => {
        setDisplayStyle('none')
      }, 250);
      return () => {
        clearTimeout(timeout1)
        clearTimeout(timeout2)
      }; // Clean up the timeout if the component unmounts
    }
  }, [isVisible]);

  useEffect(() => {
    if(location.pathname == '/' || location.pathname == '/registrarse'){
      setIsLogged(false)
    }else {
      setIsLogged(true)
    }
  }, [location.pathname])

  return (
    <nav 
      onClick={checkForOutsideClick}
      className="nav"
    >
      {isLogged && <>
        <img
          onClick={toggleIsVisible}
          className='button' 
          src={navBtn} 
        />
        <div 
          className="container"
          style={{
            translate: isVisible ? '0' : '-650px'
          }}
        >
          <div className="header">
            <h2>Administrador</h2>
            <img 
              onClick={toggleIsVisible}
              className='x-icon'
              src={xIcon}
            />
          </div>
          <div className="wrapper">
            <div className="link-list">
              {linkList.map(link => 
                <Link 
                  className={isActive(link) ? 'selected' : ''} 
                  to={link.url}
                  key={link.key}
                >{link.name}</Link>
              )}
            </div>
            <div className="profile">
              <div className="user-info">
                <img 
                  className='user-img'
                  src={userImg}
                />
                <div className="copy">
                  <h2>Alexander</h2>
                  <span>alexandersolis3</span>
                </div>
              </div>
              <Link to='/'>
                <img
                  className='logout-icon'
                  src={logoutIcon}
                />
              </Link>
            </div>
          </div>
        </div>
        <div 
          className={`dark-bg`}
          style={{
            opacity: opacityStyle,
            display: displayStyle
          }}
        ></div>
      </> }
    </nav>
  );
}
 
export default Nav;