import { useEffect, useState } from "react";
import Selector from "../../reusable/Selector";

const ManageAirline = ({data, dataLabels, isEditMode}) => {
  const [selectedData, setSelectedData] = useState(data ? data : null)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels)

  const changeSelectedData = index => {
    switch(index){
      case 0:
        setSelectedData(data ? data : null)
        setSelectedLabels(dataLabels)
        break;
    }
  }

  return (
    <div className="datos-empleado">
      <div className="data-table">
        {Object.keys(selectedLabels).map((att, index) => {
          let value = selectedData ? selectedData[att] : ''
          let label = selectedLabels[att]

          return (
            <div key={index} style={{marginBottom: '30px'}}>
              <span 
                style={{fontWeight: 700, marginRight: '20px'}}
              >{ label }:</span>
              {!isEditMode
                ? <span>{ value }</span>
                : <input placeholder={ value } type="text" />
              }
            </div>
          )
        })}
      </div>
    </div> 
  );
}
 
export default ManageAirline;