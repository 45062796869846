import { useEffect, useState } from 'react';
import FilterIcon from '../reusable/FilterIcon';
import FilterMenu from '../reusable/FilterMenu';

const Searcher = ({ employeesData, onChange }) => {
  const [employees, setEmployees] = useState(null)
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filters, setFilters] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const filterByQuery = filteredEmployees => {
    filteredEmployees = !searchQuery
    ? filteredEmployees
    : filteredEmployees.filter(e => {
      let name = e.details.personal.completeName.toString().toLowerCase()
      let id = e.details.personal.id.toString().toLowerCase()
      return (
        ((filters.name && name.includes(searchQuery.toLowerCase())) ||
        (filters.id && id.includes(searchQuery.toLowerCase())))
      )
    });

    return filteredEmployees
  }
  const filterEmployees = () => {
    let filteredEmployees = employeesData
    let hasFilters = filters ? filters.name || filters.id : false;


    if(!searchQuery || !hasFilters) {
      setEmployees(employeesData)
      return
    }
    
    filteredEmployees = filterByQuery(filteredEmployees)

    setEmployees(filteredEmployees)
  }

  useEffect(() => {
    if(employeesData)
      setEmployees(employeesData)
  }, [])

  useEffect(() => {
    filterEmployees()
  }, [filters, searchQuery])

  useEffect(() => {
    onChange(employees ? employees : employeesData)
  }, [employees])

  return (
    <div className="row">
      <input 
        className='searcher'
        onChange={e => setSearchQuery(e.target.value.toLowerCase())}
        value={searchQuery}
        type="text" 
        placeholder="Search"
      />
      <FilterIcon onClick={ () => setShowFilterMenu(!showFilterMenu)} />
      <FilterMenu
        show={showFilterMenu}
        onChange={filters => setFilters(filters)}
      />
    </div>
  );
}
 
export default Searcher;

/*
{
  name: 'Jose',
  details: {
    personal: {
      completeName: "Jose Raul Rodriguez Santos",
      id: 1000000,
      gender: 'Hombre',
      residence: "Del centro comercial Plaza Viquez, 100m Norte",
      birthDate: '6/11/1970',
    },
  }
},
*/