import '../styles/register.css'
import loginLogo from '../assets/login-logo.svg'
import userLogo from '../assets/user-logo.svg'
import lockLogo from '../assets/lock-logo.svg'
import { Link } from 'react-router-dom'
import PopUp from './reusable/PopUp'
import { useState } from 'react'
import useToggleState from './hooks/useToggleState'

const Login = () => {  
  const popUpContent = (
    <div className="content">
      <h2>Usuario Creado <br /> Exitosamente</h2>
      <p>Para activar su cuenta, favor contactar a <a target='_blank' href="mailto:soporte@aereopuerto.com">Soporte</a> para que asignen el rol indicado.</p>
    </div>
  )
    
  const [isVisible, toggleIsVisible] = useToggleState(false)

  return (
    <div className="login">
      <PopUp 
        isVisible={isVisible} 
        toggleIsVisible={toggleIsVisible}
        content={popUpContent}
      />
      <form className="login-form">
        <img 
          src={loginLogo}
          className='login-logo'
        />

        <div className="input-icon-wrapper username">
          <img src={userLogo}/>
          <input 
            type="text" 
            placeholder="Nombre Completo"
          />
        </div>

        <div className="input-icon-wrapper username">
          <img src={userLogo}/>
          <input 
            type="text" 
            placeholder="Usuario"
          />
        </div>

        <div className="input-icon-wrapper username">
          <img src={lockLogo}/>
          <input 
            type="text" 
            placeholder="Contrase&ntilde;a"
            className='password'
         />
        </div>

        <div className="input-icon-wrapper username">
          <img src={lockLogo}/>
          <input 
            type="text" 
            placeholder="Confirmar Contrase&ntilde;a"
            className='password'
         />
        </div>

        <button onClick={e => {
          e.preventDefault()
          toggleIsVisible()
        }}>Crear</button>

      </form>
      <div className="create-account">
        <span><Link className='register' to='/'>Volver</Link></span>
      </div>
    </div>
  );
}
 
export default Login;