import React, { useEffect, useState } from "react";

const FilterMenu = ({ show, onChange }) => {
  // State variables for each checkbox
  const [name, setName] = useState(false);
  const [id, setId] = useState(false);

  useEffect(() => {
    onChange({ name, id })
  }, [name, id])

  return (
    <form
      className="filter-menu"
      style={{ display: show ? "flex" : "none" }}
    >
      <div>
        <label>Nombre</label>
        <input
          type="checkbox"
          checked={name}
          onChange={e => setName(e.target.checked)}
        />
      </div>
      <div>
        <label>C&eacute;dula</label>
        <input
          type="checkbox"
          checked={id}
          onChange={e => setId(e.target.checked)}
        />
      </div>
    </form>
  );
};

export default FilterMenu;