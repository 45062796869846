import faviconPng from '../../assets/favicon.png'

const useNotifications = () => {
  const fireNotification = (title, body) => {
    if ("Notification" in window) {
      Notification.requestPermission().then((perm) => {
        if (perm === "granted") {
          const notification = new Notification(title, {
            body: body,
            icon: faviconPng,
          });
  
          setTimeout(() => {
            notification.close();
          }, 10000);
        } else {
          console.log("Notifications not granted.");
        }
      });
    } else {
      // Fallback for iOS Safari or unsupported browsers
      alert(`${title}: ${body}`);
    }
  };

  return { fireNotification }
}

export default useNotifications