import { Link, useLocation } from 'react-router-dom';
import aeroLogo from '../assets/aero-logo.png'
import { useState, useEffect } from 'react';

const Footer = () => {
  const [isLogged, setIsLogged] = useState(false);
  const location = useLocation();

  
  useEffect(() => {
    if(location.pathname == '/' || location.pathname == '/registrarse'){
      setIsLogged(false)
    }else {
      setIsLogged(true)
    }
  }, [location.pathname])

  return isLogged && (
    <div className="footer">
      <Link to={'/'}>
        <img src={aeroLogo} alt="" />
      </Link>
      <a target='_blank' style={{color: 'white'}} href="mailto:soporte@aeropuerto.com">Ayuda y Soporte</a>
    </div>
  );
}
 
export default Footer;