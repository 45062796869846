import { useEffect, useState } from "react";
import Selector from "../reusable/Selector";

const DatosEmpleado = ({employeeDetails, employeeDetailsLabels, isEditMode}) => {
  const selectorOptions = [
    {
      name: 'Personales',
      id: 0
    },
    {
      name: 'Laborales',
      id: 1
    },
    {
      name: 'Contactos',
      id: 2
    }
  ]
  const [selectedData, setSelectedData] = useState(employeeDetails['personal'])
  const [selectedLabels, setSelectedLabels] = useState(employeeDetailsLabels['personal'])
  const [populatedElement, setPopulatedElement] = useState(<></>)

  const changeSelectedData = index => {
    switch(index){
      case 0:
        setSelectedData(employeeDetails['personal'])
        setSelectedLabels(employeeDetailsLabels['personal'])
        break;
      case 1:
        setSelectedData(employeeDetails['job'])
        setSelectedLabels(employeeDetailsLabels['job'])
        break;
      case 2:
        setSelectedData(employeeDetails['contact'])
        setSelectedLabels(employeeDetailsLabels['contact'])
        break;
    }
  }
  const updateRenderedData = () => {
    let elementArr = []
    
    for(let att in selectedData){
      let element = (
        <div style={{marginBottom: '30px'}}>
          <span style={{fontWeight: 700, marginRight: '20px'}}>{ selectedLabels[att] }:</span>
          {!isEditMode
            ? <span>{ selectedData[att] }</span>
            : <input 
                type="text"
                placeholder={selectedData[att]}
              />
          }
        </div>
      )
      elementArr.push(element)
    }

    setPopulatedElement(
      <div className="data-table">
        {elementArr.map(element => element)}
      </div>
    )
  }

  useEffect(() => {
    updateRenderedData()
  }, [selectedData])

  return (
    <div className="datos-empleado">
      <Selector options={selectorOptions} getSelectedIndex={index => changeSelectedData(index)} />
      {selectedData && populatedElement}
    </div> 
  );
}
 
export default DatosEmpleado;