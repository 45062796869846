import { useEffect, useState } from "react";
import Selector from "../reusable/Selector";

const AgregarEmpleado = () => {
  const data = {
    details: {
      completeName: "",
      id: "",
      gender: '',
      residence: "",
      birthDate: '',
      ocpuation: '',
      department: '',
      startDate: '',
      status: '',
      phone: '',
      email: '',
    },
    payroll: {
      schedule: "",
      days: "",
      extraHours: '',
      salary: '',
      payPerHour: ''
    },
  }
  const dataLabels = {
    details: {
      completeName: "Nombre Completo",
      id: "Cédula",
      gender: 'Género',
      residence: "Residencia",
      birthDate: 'Fecha de Nacimiento',
      ocpuation: 'Puesto',
      department: 'Departamento',
      startDate: 'Fecha de Ingreso',
      status: 'Estado',
      phone: 'Teléfono',
      email: 'Correo',
    },
    payroll: {
      schedule: "Horario",
      days: "Días",
      extraHours: 'Horas Extra',
      salary: 'Salario',
      payPerHour: 'Salario por Hora'
    },
  }
  const selectorOptions = [
    {
      name: 'Personal',
      id: 0
    },
    {
      name: 'Nomina',
      id: 1
    }
  ]
  const [selectedData, setSelectedData] = useState(data.details)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels.details)
  const [populatedElement, setPopulatedElement] = useState(<></>)

  const changeSelectedData = index => {
    switch(index){
      case 0:
        setSelectedData(data.details)
        setSelectedLabels(dataLabels.details)
        break;
      case 1:
        setSelectedData(data.payroll)
        setSelectedLabels(dataLabels.payroll)
        break;
    }
  }
  const updateRenderedData = () => {
    let elementArr = []
    
    for(let att in selectedData){
      let element = (
        <div style={{marginBottom: '30px'}}>
          <span 
            style={{fontWeight: 700, marginRight: '20px'}}
          >{ selectedLabels[att] }:</span>
          <input 
            type="text"
            placeholder={selectedData[att]}
          />
        </div>
      )
      elementArr.push(element)
    }

    setPopulatedElement(
      <div className="data-table">
        {elementArr.map(element => element)}
      </div>
    )
  }

  useEffect(() => {
    updateRenderedData()
  }, [selectedData])

  return (
    <div className="datos-empleado">
      <Selector options={selectorOptions} getSelectedIndex={index => changeSelectedData(index)} />
      {selectedData && populatedElement}
    </div> 
  );
}
 
export default AgregarEmpleado;