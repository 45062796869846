const TracksMap = ({ resource, handleMapClick }) => {
  const getBgColor = id => resource[id].schedule[0].state
  
  return (
    <svg width="803" height="554" viewBox="0 0 803 554" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="23.7778" height="561.245" transform="matrix(0.979672 0.200604 -0.205811 0.978592 272.02 0)" fill="#D8D8D9"/>
      <rect width="23.7778" height="23.78" transform="matrix(0.979672 0.200604 -0.205811 0.978592 272.02 0)" 
        fill={getBgColor(0)}
        onClick={() => handleMapClick(0)}  
      />

      <rect width="23.5723" height="831.217" transform="matrix(0.558946 0.829204 -0.83603 0.548683 694.922 18.3047)" fill="#D8D8D9"/>
      <rect width="23.5723" height="23.57" transform="matrix(0.558946 0.829204 -0.83603 0.548683 694.921 18.3047)" 
        fill={getBgColor(1)}
        onClick={() => handleMapClick(1)}  
      />

      <rect width="23.5723" height="831.217" transform="matrix(0.558946 0.829204 -0.83603 0.548683 726.564 57.6807)" fill="#D8D8D9"/>
      <rect width="23.5723" height="23.57" transform="matrix(0.558946 0.829204 -0.83603 0.548683 726.564 57.6807)" 
        fill={getBgColor(2)}
        onClick={() => handleMapClick(2)}  
      />

      <rect width="23.4753" height="635.296" transform="matrix(-0.0326667 0.999466 -0.999494 -0.031806 641.412 336.216)" fill="#D8D8D9"/>
      <rect width="23.4753" height="23.48" transform="matrix(-0.0326667 0.999466 -0.999494 -0.031806 641.412 336.216)" 
        fill={getBgColor(3)}
        onClick={() => handleMapClick(3)}  
      />

      <rect width="11.8862" height="221.061" transform="matrix(0.979672 0.200604 -0.205811 0.978592 120.357 44.0459)" fill="#D8D8D9"/>
      <rect width="11.8862" height="11.89" transform="matrix(0.979672 0.200604 -0.205811 0.978592 120.356 44.0459)" 
        fill={getBgColor(4)}
        onClick={() => handleMapClick(4)}  
      />

      <rect width="11.8862" height="221.061" transform="matrix(0.979672 0.200604 -0.205811 0.978592 177.07 44.0459)" fill="#D8D8D9"/>
      <rect width="11.8862" height="11.89" transform="matrix(0.979672 0.200604 -0.205811 0.978592 176.939 44.0459)" 
        fill={getBgColor(5)}
        onClick={() => handleMapClick(5)}  
      />

      <rect width="11.7861" height="222.951" transform="matrix(0.574204 0.818712 -0.82583 0.563919 754.649 122.389)" fill="#D8D8D9"/>
      <rect width="11.7861" height="11.79" transform="matrix(0.574204 0.818712 -0.82583 0.563919 754.649 122.389)" 
        fill={getBgColor(6)}
        onClick={() => handleMapClick(6)}  
      />

      <rect width="11.7861" height="222.951" transform="matrix(0.574204 0.818712 -0.82583 0.563919 795.725 160.975)" fill="#D8D8D9"/>
      <rect width="11.7861" height="11.79" transform="matrix(0.574204 0.818712 -0.82583 0.563919 795.229 161)" 
        fill={getBgColor(7)}
        onClick={() => handleMapClick(7)}  
      />
    </svg>
  );
}
 
export default TracksMap;