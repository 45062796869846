import { useState } from "react";
/*
  options: espera un objeto con nombre y id {name: 'titulo', id: 0}
  
  getSelectedIndex: en una llamada del componente, el campo getSelectedIndex nos permite pasar un callback
  donde el callback tiene como parametro index, y podemos ingresar una funcion para registrar el nuevo index
*/
const Selector = ({options, getSelectedIndex}) => {
  const [selected, setSelected] = useState(0);
  const handleSelectedChange = index => {
    setSelected(index)
    getSelectedIndex(index)
  }

  return (
    <div className="selector">
      {options.map(option => (
        <span
          key={option.id}
          onClick={() => handleSelectedChange(option.id)}
          className={selected === option.id ? 'selected' : ''}
        >
          {option.name}
        </span>
      ))}
    </div>
  );
}
 
export default Selector;