import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Nav from './components/Nav';
import Footer from './components/Footer'
import Login from './components/Login';
import Register from './components/Register';
import GestionRoles from './components/administration/GestionRoles';
import GestionEmpleados from './components/human-resources/GestionEmpleados';
import GestionGatesPistas from './components/coordination/gatesTracks/GestionGatesPistas';
import FlightManagement from './components/coordination/flights/FlightManagement';
import AirlineManagement from './components/coordination/airlines/AirlineManagement';
import PassengerManagement from './components/coordination/passengers/PassengerManagement';
import Map from './components/coordination/map/Map';

function App() {
  return (
    <div className="App">
      <Router>
        <Nav />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/registrarse' element={<Register />} />
          <Route path='/gestion-roles' element={<GestionRoles />} />
          <Route path='/mapa-puertos-pistas' element={<Map />} />
          <Route path='/gestion-puertos-pistas' element={<GestionGatesPistas />} />
          <Route path='/gestion-vuelos' element={<FlightManagement />} />
          <Route path='/registro-gestion-aerolineas' element={<AirlineManagement />} />
          <Route path='/gestion-pasajeros' element={<PassengerManagement />} />
          <Route path='/gestion-empleados' element={<GestionEmpleados />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
