import { useEffect, useState } from 'react';
import '../../styles/human-resources/gestionEmpleados.css'
import trashSvg from '../../assets/trash.svg'

import Searcher from '../reusable/Searcher';
import DatosEmpleado from './DatosEmpleado';
import useToggleState from '../hooks/useToggleState'
import PopUp from '../reusable/PopUp'
import DatosNomina from './DatosNomina';
import AddElement from '../reusable/AddElement';
import AgregarEmpleado from './AgregarEmpleado';
import useNotifications from '../hooks/useNotifications';

const GestionEmpleados = () => {
  const employeesDataLabels = {
    name: "Nombre",
    details: {
      personal: {
        completeName: "Nombre Completo",
        id: "Cédula",
        gender: 'Género',
        residence: "Residencia",
        birthDate: 'Fecha de Nacimiento',
      },
      job: {
        ocpuation: 'Puesto',
        department: 'Departamento',
        startDate: 'Fecha de Ingreso',
        status: 'Estado',
      },
      contact: {
        phone: 'Teléfono',
        email: 'Correo',
      },
    },
    payroll: {
      schedule: "Horario",
      days: "Días",
      extraHours: 'Horas Extra',
      salary: 'Salario',
      payPerHour: 'Salario por Hora'
    },
  }
  const employeesData = [
    {
      name: 'Jose',
      details: {
        personal: {
          completeName: "Jose Raul Rodriguez Santos",
          id: 1000000,
          gender: 'Hombre',
          residence: "Del centro comercial Plaza Viquez, 100m Norte",
          birthDate: '6/11/1970',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '7/11/2023',
          status: 'Enfermo',
        },
        contact: {
          phone: '83028450',
          email: 'joseraul@gmail.com',
        },
      },
      payroll: {
        schedule: '9:00 - 17:00',
        days: 'L, K, M, S, D',
        extraHours: 5,
        salary: '100000',
        payPerHour: (100000 / 160).toFixed(2),
      },
    },
    {
      name: 'Ana',
      details: {
        personal: {
          completeName: "Ana Maria Castro Lopez",
          id: 1000001,
          gender: 'Mujer',
          residence: "100m Este del Parque Central",
          birthDate: '15/03/1985',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '1/10/2022',
          status: 'Activa',
        },
        contact: {
          phone: '71039485',
          email: 'ana.castro@gmail.com',
        },
      },
      payroll: {
        schedule: '7:00 - 15:00',
        days: 'L, M, K, J, V',
        extraHours: 0,
        salary: '95000',
        payPerHour: (95000 / 160).toFixed(2),
      },
    },
    {
      name: 'Luis',
      details: {
        personal: {
          completeName: "Luis Alberto Jimenez Mora",
          id: 1000002,
          gender: 'Hombre',
          residence: "Frente a la iglesia principal",
          birthDate: '22/09/1992',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '5/04/2018',
          status: 'En Vacaciones',
        },
        contact: {
          phone: '60584939',
          email: 'luis.jimenez@gmail.com',
        },
      },
      payroll: {
        schedule: '13:00 - 21:00',
        days: 'L, M, J, S',
        extraHours: 3,
        salary: '105000',
        payPerHour: (105000 / 160).toFixed(2),
      },
    },
    {
      name: 'María',
      details: {
        personal: {
          completeName: "Maria Fernanda Solano Perez",
          id: 1000003,
          gender: 'Mujer',
          residence: "50m Norte del Supermarket La Fortuna",
          birthDate: '9/12/1980',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '12/01/2020',
          status: 'Activa',
        },
        contact: {
          phone: '87029123',
          email: 'maria.solano@gmail.com',
        },
      },
      payroll: {
        schedule: '14:00 - 22:00',
        days: 'K, M, J, D',
        extraHours: 7,
        salary: '110000',
        payPerHour: (110000 / 160).toFixed(2),
      },
    },
    {
      name: 'Carlos',
      details: {
        personal: {
          completeName: "Carlos Andres Morales Salas",
          id: 1000004,
          gender: 'Hombre',
          residence: "Residencial Los Jardines, casa 23",
          birthDate: '7/05/1995',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '18/09/2021',
          status: 'Enfermo',
        },
        contact: {
          phone: '72034567',
          email: 'carlos.morales@gmail.com',
        },
      },
      payroll: {
        schedule: '10:00 - 18:00',
        days: 'L, M, J, S',
        extraHours: 2,
        salary: '97000',
        payPerHour: (97000 / 160).toFixed(2),
      },
    },
    {
      name: 'Diana',
      details: {
        personal: {
          completeName: "Diana Carolina Vargas Ulate",
          id: 1000005,
          gender: 'Mujer',
          residence: "100m Oeste del Banco Nacional",
          birthDate: '12/07/1988',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '30/11/2019',
          status: 'Activa',
        },
        contact: {
          phone: '61039485',
          email: 'diana.vargas@gmail.com',
        },
      },
      payroll: {
        schedule: '6:00 - 14:00',
        days: 'L, K, V, D',
        extraHours: 4,
        salary: '101000',
        payPerHour: (101000 / 160).toFixed(2),
      },
    },
    {
      name: 'Miguel',
      details: {
        personal: {
          completeName: "Miguel Angel Rojas Chacon",
          id: 1000006,
          gender: 'Hombre',
          residence: "Casa 5, Barrio Los Cipreses",
          birthDate: '20/11/1975',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '2/03/2016',
          status: 'Suspendido',
        },
        contact: {
          phone: '53029148',
          email: 'miguel.rojas@gmail.com',
        },
      },
      payroll: {
        schedule: '8:00 - 16:00',
        days: 'L, M, J',
        extraHours: 1,
        salary: '92000',
        payPerHour: (92000 / 160).toFixed(2),
      },
    },
    {
      name: 'Sandra',
      details: {
        personal: {
          completeName: "Sandra Elena Paniagua Rojas",
          id: 1000007,
          gender: 'Mujer',
          residence: "Calle 25, San Jose",
          birthDate: '3/08/1990',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '7/06/2017',
          status: 'Activa',
        },
        contact: {
          phone: '84039576',
          email: 'sandra.paniagua@gmail.com',
        },
      },
      payroll: {
        schedule: '12:00 - 20:00',
        days: 'K, M, J, S',
        extraHours: 6,
        salary: '103000',
        payPerHour: (103000 / 160).toFixed(2),
      },
    },
    {
      name: 'Rosa',
      details: {
        personal: {
          completeName: "Rosa Emilia Fernandez Lopez",
          id: 1000008,
          gender: 'Mujer',
          residence: "Frente al colegio Santa Rosa",
          birthDate: '25/09/1983',
        },
        job: {
          ocpuation: 'Conserge',
          department: 'Mantenimiento & Limpieza',
          startDate: '12/02/2022',
          status: 'Activa',
        },
        contact: {
          phone: '72038955',
          email: 'rosa.fernandez@gmail.com',
        },
      },
      payroll: {
        schedule: '11:00 - 19:00',
        days: 'L, M, J, V',
        extraHours: 3,
        salary: '99000',
        payPerHour: (99000 / 160).toFixed(2),
      },
    },
  ];
  
  

  const { fireNotification } = useNotifications();
  const [employees, setEmployees] = useState(employeesData)
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [entrees, setEntrees] = useState(null)
  const [isEditMode, toggleIsEditMode] = useToggleState(false);
  const [datosEmpleadoShowState, setDatosEmpleadoShowState] = useState({})
  const [datosNominaShowState, setDatosNominaShowState] = useState({})
  const [showAddUserPopUp, toggleShowAddUserPopUp] = useToggleState(false)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: employeesData.length,
    selectedIndex: 1
  });
  const toggleDatosEmpleadoPopUp = id => {
    setDatosEmpleadoShowState(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const toggleDatosNominaPopUp = id => {
    setDatosNominaShowState(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const changeSelectedIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderPage = employees => {
    if(!employees) return
    renderEntrees(employees)
    renderPaginateSelector(employees)
  }
  const renderEntrees = data => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage
    let elementArray = [];

    if(isEditMode){
      let id = 'add-item'
      elementArray.push(
        <>
          <AddElement 
            onClick={toggleShowAddUserPopUp}
            itemTitle={"Empleado"}
          />
          <PopUp
            isVisible={showAddUserPopUp}
            toggleIsVisible={() => toggleShowAddUserPopUp(id)}
            content={showAddUserPopUp && (
              <AgregarEmpleado />
            )}
          />
        </>
      )
    }

    for(let i = startIndex; i < endIndex; i++){
      if(!data[i]) continue

      let employee = data[i]
      let id = employee.details.personal.id
      let element = (
        <div key={id} className="entree">
          <span>{ employee.name }</span>

          <span
            className='clickable'
            onClick={() => toggleDatosEmpleadoPopUp(id)}
          >{isEditMode ? 'Editar' : 'Ver'} Datos Personales</span>
          <PopUp
            isVisible={datosEmpleadoShowState[id]}
            toggleIsVisible={() => toggleDatosEmpleadoPopUp(id)}
            content={datosEmpleadoShowState[id] && (
              <DatosEmpleado 
                isEditMode={isEditMode}
                employeeDetailsLabels={employeesDataLabels.details} 
                employeeDetails={employee.details} 
              />
            )}
          />
          <span
            className='clickable'
            onClick={() => toggleDatosNominaPopUp(id)}
          >{isEditMode ? 'Editar' : 'Ver'} N&oacute;mina</span>
          <PopUp
            isVisible={datosNominaShowState[id]}
            toggleIsVisible={() => toggleDatosNominaPopUp(id)}
            content={datosNominaShowState[id] && (
              <DatosNomina 
                isEditMode={isEditMode}
                dataLabels={employeesDataLabels.payroll} 
                data={employee.payroll} 
              />
            )}
          />

          {isEditMode && <img src={trashSvg} />}
        </div>
      )
      elementArray.push(element)
    }

    setEntrees(elementArray)
  }
  const renderPaginateSelector = data => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => changeIndex(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => changeIndex(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => changeIndex(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const changeIndex = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeSelectedIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeSelectedIndex(currentIndex - 1)
      return
    }

    changeSelectedIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Empleados"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderPage(employees)
  }, [tableController, employees, datosEmpleadoShowState, datosNominaShowState, isEditMode, showAddUserPopUp])

  useEffect(() => {
    setTableController(prevState => (
      {...prevState, dataItems: employees.length}
    ))
  }, [employees])

  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      <Searcher 
        employeesData={employeesData}
        onChange={filteredEmployees => setEmployees(filteredEmployees)}
      />
      <div className="table">
        <div className="titles">
          <span>Nombre</span>
          <span
          >Datos Personales</span>
          <span>N&oacute;mina</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default GestionEmpleados;