import { useEffect, useState } from 'react';
import '../../../styles/administration/gestion-roles.css'
import trashSvg from '../../../assets/trash.svg'

import useToggleState from '../../hooks/useToggleState'
import PopUp from '../../reusable/PopUp'
import AddElement from '../../reusable/AddElement';
import ManageAirline from './ManageAirline.js';
import ReportInvoiceAirline from './ReportInvoiceAirline.js';
import useNotifications from '../../hooks/useNotifications';

const AirlineManagement = () => {
  const reportInvoiceLabels = {
    startDate: "Fecha Inicial",
    endDate: 'Fecha Final',
    airline: "Aerolínea",
    resource: "Recurso"
  }  
  const airlinesLabels = {
    name: "Aerolínea",
    code: 'Código',
    country: "País",
    email: "Correo"
  }
  const airlinesData = [
    {
      id: 0,
      name: "Copa Airlines",
      code: "CPA",
      country: "Panamá",
      email: "contacto@copaairlines.com"
    },
    {
      id: 1,
      name: "American Airlines",
      code: "AAL",
      country: "Estados Unidos",
      email: "support@aa.com"
    },
    {
      id: 2,
      name: "Avianca",
      code: "AVA",
      country: "Colombia",
      email: "info@avianca.com"
    },
    {
      id: 3,
      name: "United Airlines",
      code: "UAL",
      country: "Estados Unidos",
      email: "customer.service@united.com"
    },
    {
      id: 4,
      name: "Delta Airlines",
      code: "DAL",
      country: "Estados Unidos",
      email: "support@delta.com"
    },
    {
      id: 5,
      name: "Iberia",
      code: "IBE",
      country: "España",
      email: "atencioncliente@iberia.com"
    },
    {
      id: 6,
      name: "Gol Linhas Aéreas",
      code: "GOL",
      country: "Brasil",
      email: "sac@gol.com.br"
    },
    {
      id: 7,
      name: "Lufthansa",
      code: "DLH",
      country: "Alemania",
      email: "service@lufthansa.com"
    },
    {
      id: 8,
      name: "KLM",
      code: "KLM",
      country: "Países Bajos",
      email: "info@klm.com"
    },
    {
      id: 9,
      name: "Air France",
      code: "AFR",
      country: "Francia",
      email: "contact@airfrance.com"
    }
  ];
  
   
   
  const selectorData = [{name: '', id: 0}]

  const { fireNotification } = useNotifications();
  const [items, setItems] = useState(airlinesData)
  const [entrees, setEntrees] = useState(null)                         //entree se refiere a cada registro en la tabla
  const [isEditMode, toggleIsEditMode] = useToggleState(false)
  const [showAddPopUp, toggleShowAddPopUp] = useToggleState(false)
  const [showAddEditPopUp, setShowAddEditPopUp] = useState({})
  const [showReportInvoicePopUp, setShowReportInvoicePopUp] = useState({})
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: airlinesData.length,
    selectedIndex: 1
  });

  const toggleShowAddEditPopUp = id => {
    setShowAddEditPopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const toggleShowReportInvoicePopUp = id => {
    setShowReportInvoicePopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const changeTableControllerIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderTable = (items, tableController) => {
    if(!items) return
    renderEntrees(items, tableController)
    renderPaginateSelector(items, tableController)
  }
  const renderEntrees = (data, tableController) => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage

    setEntrees(
      data.slice(startIndex, endIndex)
      .filter(item => item)
      .map(item => {
        const { id, name } = item

        return (
          <div key={id} className="entree">
            <span>{ name }</span>
            <span
              className='clickable'
              onClick={() => toggleShowAddEditPopUp(id)}
            >{isEditMode ? 'Editar' : 'Ver'} Datos Aerol&iacute;nea</span>
            <PopUp
              isVisible={showAddEditPopUp[id]}
              toggleIsVisible={() => toggleShowAddEditPopUp(id)}
              content={showAddEditPopUp[id] && (
                <ManageAirline 
                  isEditMode={isEditMode}
                  dataLabels={airlinesLabels} 
                  data={item} 
                />
              )}
            />
            <div style={{display: 'flex', gap: '20px'}}>
              <button
                onClick={() => toggleShowReportInvoicePopUp(id)}
              >Reporte</button>
              <button
                onClick={() => toggleShowReportInvoicePopUp(id)}
              >Factura</button>
              <PopUp
                isVisible={showReportInvoicePopUp[id]}
                toggleIsVisible={() => toggleShowReportInvoicePopUp(id)}
                content={showReportInvoicePopUp[id] && (
                  <ReportInvoiceAirline 
                    isEditMode={true}
                    dataLabels={reportInvoiceLabels} 
                  />
                )}
              />
            </div>
            {isEditMode && <img src={trashSvg} />}
          </div>
        )
      })
    )
  }
  const renderPaginateSelector = (data, tableController) => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => handleIndexChange(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => handleIndexChange(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => handleIndexChange(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const handleIndexChange = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeTableControllerIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeTableControllerIndex(currentIndex - 1)
      return
    }

    changeTableControllerIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Aerolíneas"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderTable(items, tableController)
  }, [tableController, items, showAddPopUp, isEditMode, showAddEditPopUp, showReportInvoicePopUp])

  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      {/* <Selector
        options={selectorData}
        getSelectedIndex={index => toggleIsComming(index)}
      /> */}
      <div className="table">
        <div className="titles">
          <span>Aerol&iacute;nea</span>
          <span>Datos Aerol&iacute;nea</span>
          <span>Generar</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">

          {isEditMode && <>
            <AddElement 
              itemTitle={'Ocupación'} 
              onClick={toggleShowAddPopUp}
            />
            <PopUp
              isVisible={showAddPopUp}
              toggleIsVisible={toggleShowAddPopUp}
              content={showAddPopUp && (
                <ManageAirline 
                  isEditMode={true}
                  dataLabels={airlinesLabels} 
                />
              )}
            />
          </>}
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default AirlineManagement;