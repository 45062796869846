import { useEffect, useState } from "react";
import Selector from "../../reusable/Selector";

const ManageFlight = ({data, dataLabels, isEditMode}) => {
  const selectorOptions = [
    {
      name: 'Aéropuerto',
      id: 0
    },
    {
      name: 'Lugar & Tiempo',
      id: 1
    },
  ]
  
  const [selectedData, setSelectedData] = useState(data ? data.airport : null)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels.airport)

  const changeSelectedData = index => {
    switch(index){
      case 0:
        setSelectedData(data ? data.airport : null)
        setSelectedLabels(dataLabels.airport)
        break;
      case 1:
        setSelectedData(data ? data.timePlace : null)
        setSelectedLabels(dataLabels.timePlace)
        break;
    }
  }

  return (
    <div className="datos-empleado">
      <Selector options={selectorOptions} getSelectedIndex={index => changeSelectedData(index)} />
      <div className="data-table">
        {Object.keys(selectedLabels).map((att, index) => {
          let value = selectedData ? selectedData[att] : ''
          let label = selectedLabels[att]

          return (
            <div key={index} style={{marginBottom: '30px'}}>
              <span 
                style={{fontWeight: 700, marginRight: '20px'}}
              >{ label }:</span>
              {!isEditMode
                ? <span>{ value }</span>
                : <input placeholder={ value } type="text" />
              }
            </div>
          )
        })}
      </div>
    </div> 
  );
}
 
export default ManageFlight;