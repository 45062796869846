import { useEffect, useState } from 'react';
import '../../../styles/administration/gestion-roles.css'
import trashSvg from '../../../assets/trash.svg'

import useToggleState from '../../hooks/useToggleState'
import PopUp from '../../reusable/PopUp'
import AddElement from '../../reusable/AddElement';
import Selector from '../../reusable/Selector';
import EditGatesTracks from './EditGatesTracks';
import AddGatesTracks from './AddGatesTracks'
import useNotifications from '../../hooks/useNotifications';

const GestionGatesPistas = () => {
  const ocupationDataLabels = {
    gates: {
      resourceId: 'Puerto',
      ocupationRange: 'Rango de Ocupación',
      occupiedBy: "Ocupado Por"
    },
    tracks: {
      resourceId: 'Pista',
      ocupationRange: 'Rango de Ocupación',
      occupiedBy: "Ocupado Por"
    }
  }
  const ocupationData = {
    gates: [
      { id: 0, resourceId: 5, ocupationRange: '10:00 - 10:25', occupiedBy: 'American Airlines' },
      { id: 1, resourceId: 2, ocupationRange: '10:30 - 10:55', occupiedBy: 'Delta Airlines' },
      { id: 2, resourceId: 8, ocupationRange: '11:00 - 11:25', occupiedBy: 'United Airlines' },
      { id: 3, resourceId: 11, ocupationRange: '11:30 - 11:55', occupiedBy: 'Cope Airlines' },
      { id: 4, resourceId: 7, ocupationRange: '12:00 - 12:25', occupiedBy: 'American Airlines' },
      { id: 5, resourceId: 1, ocupationRange: '12:30 - 12:55', occupiedBy: 'Delta Airlines' },
      { id: 6, resourceId: 6, ocupationRange: '13:00 - 13:25', occupiedBy: 'United Airlines' },
      { id: 7, resourceId: 4, ocupationRange: '13:30 - 13:55', occupiedBy: 'Cope Airlines' },
      { id: 8, resourceId: 9, ocupationRange: '14:00 - 14:25', occupiedBy: 'American Airlines' },
      { id: 9, resourceId: 10, ocupationRange: '14:30 - 14:55', occupiedBy: 'Delta Airlines' }
    ],
    tracks: [
      { id: 0, resourceId: 4, ocupationRange: '10:00 - 10:25', occupiedBy: 'American Airlines' },
      { id: 1, resourceId: 1, ocupationRange: '10:30 - 10:55', occupiedBy: 'Delta Airlines' },
      { id: 2, resourceId: 5, ocupationRange: '11:00 - 11:25', occupiedBy: 'United Airlines' },
      { id: 3, resourceId: 3, ocupationRange: '11:30 - 11:55', occupiedBy: 'Cope Airlines' },
      { id: 4, resourceId: 7, ocupationRange: '12:00 - 12:25', occupiedBy: 'American Airlines' },
      { id: 5, resourceId: 5, ocupationRange: '12:30 - 12:55', occupiedBy: 'Delta Airlines' },
      { id: 6, resourceId: 6, ocupationRange: '13:00 - 13:25', occupiedBy: 'United Airlines' },
      { id: 7, resourceId: 8, ocupationRange: '13:30 - 13:55', occupiedBy: 'Cope Airlines' },
      { id: 8, resourceId: 2, ocupationRange: '14:00 - 14:25', occupiedBy: 'American Airlines' },
      { id: 9, resourceId: 8, ocupationRange: '14:30 - 14:55', occupiedBy: 'Delta Airlines' }
    ]
  };
   
  const selectorData = [
    {name: 'Puertos', id: 0},
    {name: 'Pistas', id: 1}
  ]

  const { fireNotification } = useNotifications();
  const [items, setItems] = useState(ocupationData.gates)
  const [entrees, setEntrees] = useState(null)                         //entree se refiere a cada registro en la tabla
  const [isGates, toggleIsGates] = useToggleState(true)
  const [isEditMode, toggleIsEditMode] = useToggleState(false)
  const [showAddPopUp, toggleShowAddPopUp] = useToggleState(false)
  const [showAddEditPopUp, setShowAddEditPopUp] = useState({})
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: ocupationData.gates.length,
    selectedIndex: 1
  });

  const toggleShowAddEditPopUp = id => {
    setShowAddEditPopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const changeTableControllerIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderTable = (items, tableController) => {
    if(!items) return
    renderEntrees(items, tableController)
    renderPaginateSelector(items, tableController)
  }
  const renderEntrees = (data, tableController) => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage

    setEntrees(
      data.slice(startIndex, endIndex)
      .filter(item => item)
      .map(item => {
        const { id, resourceId } = item

        return (
          <div key={id} className="entree">
            <span>{ isGates ? 'Puerto' : 'Pista' } { resourceId }</span>
            <span
              className='clickable'
              onClick={() => toggleShowAddEditPopUp(id)}
            >{isEditMode ? 'Editar' : 'Ver'} Ocupaci&oacute;n</span>
            <PopUp
              isVisible={showAddEditPopUp[id]}
              toggleIsVisible={() => toggleShowAddEditPopUp(id)}
              content={showAddEditPopUp[id] && (
                <EditGatesTracks 
                  isEditMode={isEditMode}
                  dataLabels={isGates ? ocupationDataLabels.gates : ocupationDataLabels.tracks} 
                  data={item} 
                />
              )}
            />
            {isEditMode && <img src={trashSvg} />}
          </div>
        )
      })
    )
  }
  const renderPaginateSelector = (data, tableController) => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => handleIndexChange(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => handleIndexChange(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => handleIndexChange(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const handleIndexChange = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeTableControllerIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeTableControllerIndex(currentIndex - 1)
      return
    }

    changeTableControllerIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Puertos y Pistas"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderTable(items, tableController)
  }, [tableController, items, showAddPopUp, isEditMode, showAddEditPopUp])
  useEffect(() => {
    let newItems = isGates ? ocupationData.gates : ocupationData.tracks
    setItems(newItems)
    setTableController(prevState => (
      {...prevState, dataItems: newItems.length}
    ))
  }, [isGates])

  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      <Selector
        options={selectorData}
        getSelectedIndex={index => toggleIsGates(index)}
      />
      <div className="table">
        <div className="titles">
          <span>{isGates ? "Puerto" : "Pista"}</span>
          <span>Ocupaci&oacute;n</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">

          {isEditMode && <>
            <AddElement 
              itemTitle={'Ocupacion'} 
              onClick={toggleShowAddPopUp}
            />
            <PopUp
              isVisible={showAddPopUp}
              toggleIsVisible={toggleShowAddPopUp}
              content={showAddPopUp && (
                <AddGatesTracks />
              )}
            />
          </>}
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default GestionGatesPistas;

          /* <Entrees 
            data = {{
              items, 
              isGates, 
              toggleShowAddEditPopUp, 
              isEditMode, 
              showAddEditPopUp, 
              ocupationDataLabels,
              toggleShowAddPopUp, 
              showAddPopUp,
            }}
            tableController={tableController}
          /> */

/*
  const renderEntrees = (data, tableController) => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage
    let elementArray = [];

    if(isEditMode){
      elementArray.push(<>
        <AddElement 
          itemTitle={'Ocupacion'} 
          onClick={toggleShowAddPopUp}
        />
        <PopUp
          isVisible={showAddPopUp}
          toggleIsVisible={toggleShowAddPopUp}
          content={showAddPopUp && (
            <AddGatesTracks />
          )}
        />
      </>)
    }

    for(let i = startIndex; i < endIndex; i++){
      if(!data[i]) continue

      let item = data[i]
      let id = item.id
      let element = (

        <div key={id} className="entree">
          <span>{ isGates ? 'Puerto' : 'Pista' } { item.resourceId }</span>

          <span
            className='clickable'
            onClick={() => toggleShowAddEditPopUp(id)}
          >{isEditMode ? 'Editar' : 'Ver'} Ocupaci&oacute;n</span>
          <PopUp
            isVisible={showAddEditPopUp[id]}
            toggleIsVisible={() => toggleShowAddEditPopUp(id)}
            content={showAddEditPopUp[id] && (
              <EditGatesTracks 
                isEditMode={isEditMode}
                dataLabels={isGates ? ocupationDataLabels.gates : ocupationDataLabels.tracks} 
                data={item} 
              />
            )}
          />

          {isEditMode && <img src={trashSvg} />}
        </div>
      )
      elementArray.push(element)
    }

    setEntrees(elementArray)
  }
*/