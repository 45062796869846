import { useEffect, useState } from 'react';
import '../../../styles/administration/gestion-roles.css'
import trashSvg from '../../../assets/trash.svg'

import useToggleState from '../../hooks/useToggleState'
import PopUp from '../../reusable/PopUp'
import AddElement from '../../reusable/AddElement';
import Selector from '../../reusable/Selector';
import ViewModifyFlight from './ManageFlight';
import useNotifications from '../../hooks/useNotifications';

const GestionGatesPistas = () => {
  const flightsLabels = {
    flightName: "",
    comming: {
      flightName: "Vuelo",
      airport: {
        gate: "Puerto",
        track: 'Pista',
        airline: "Aereolínea",
        flightState: "Estado de Vuelo"
      },
      timePlace: {
        leavingHour: "Hora de Salida",
        arrivingHour: "Hora de Llegada",
        leavingDate: "Fecha de Salida",
        arrivingDate: 'Fecha de Llegada',
        origin: "Origen",
        destiny: "Destino"
      }
    },
    leaving: {
      flightName: "Vuelo",
      airport: {
        gate: "Puerto",
        track: 'Pista',
        airline: "Aereolínea",
        flightState: "Estado de Vuelo"
      },
      timePlace: {
        leavingHour: "Hora de Salida",
        arrivingHour: "Hora de Llegada",
        leavingDate: "Fecha de Salida",
        arrivingDate: 'Fecha de Llegada',
        origin: "Origen",
        destiny: "Destino"
      }
    }
  }
  const flightsData = {
    comming: [
      {
        id: 0,
        flightName: "LA-2408",
        airport: {
          gate: 3,
          track: 6,
          airline: "American Airlines",
          flightState: "Atrasado"
        },
        timePlace: {
          leavingHour: "19:00",
          arrivingHour: "1:20",
          leavingDate: "22/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Florida, Estados Unidos"
        }
      },
      {
        id: 1,
        flightName: "UA-1122",
        airport: {
          gate: 8,
          track: 4,
          airline: "United Airlines",
          flightState: "Aterrizado"
        },
        timePlace: {
          leavingHour: "15:30",
          arrivingHour: "20:45",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Houston, Estados Unidos",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 2,
        flightName: "AV-7214",
        airport: {
          gate: 5,
          track: 2,
          airline: "Avianca",
          flightState: "Atrasado"
        },
        timePlace: {
          leavingHour: "14:00",
          arrivingHour: "16:45",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Ciudad de Panamá, Panamá",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 3,
        flightName: "DL-3309",
        airport: {
          gate: 1,
          track: 1,
          airline: "Delta Airlines",
          flightState: "En Progreso"
        },
        timePlace: {
          leavingHour: "11:00",
          arrivingHour: "15:30",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Atlanta, Estados Unidos",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 4,
        flightName: "CM-0548",
        airport: {
          gate: 2,
          track: 3,
          airline: "Copa Airlines",
          flightState: "Programado"
        },
        timePlace: {
          leavingHour: "10:00",
          arrivingHour: "12:45",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Bogotá, Colombia",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 5,
        flightName: "IB-6801",
        airport: {
          gate: 9,
          track: 5,
          airline: "Iberia",
          flightState: "Aterrizado"
        },
        timePlace: {
          leavingHour: "7:00",
          arrivingHour: "15:30",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Madrid, España",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 6,
        flightName: "LH-5067",
        airport: {
          gate: 10,
          track: 6,
          airline: "Lufthansa",
          flightState: "En Progreso"
        },
        timePlace: {
          leavingHour: "22:30",
          arrivingHour: "6:00",
          leavingDate: "22/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Frankfurt, Alemania",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 7,
        flightName: "G3-4502",
        airport: {
          gate: 6,
          track: 7,
          airline: "Gol Linhas Aéreas",
          flightState: "Aterrizado"
        },
        timePlace: {
          leavingHour: "23:30",
          arrivingHour: "4:00",
          leavingDate: "22/11/2024",
          arrivingDate: "23/11/2024",
          origin: "São Paulo, Brasil",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 8,
        flightName: "KLM-7541",
        airport: {
          gate: 7,
          track: 8,
          airline: "KLM",
          flightState: "Programado"
        },
        timePlace: {
          leavingHour: "16:00",
          arrivingHour: "20:30",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Ámsterdam, Países Bajos",
          destiny: "San Jose, Costa Rica"
        }
      },
      {
        id: 9,
        flightName: "AA-3120",
        airport: {
          gate: 4,
          track: 2,
          airline: "American Airlines",
          flightState: "Atrasado"
        },
        timePlace: {
          leavingHour: "8:00",
          arrivingHour: "12:15",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "Dallas, Estados Unidos",
          destiny: "San Jose, Costa Rica"
        }
      }
    ],
    leaving: [
      {
        id: 0,
        flightName: "CA-0292",
        airport: {
          gate: 6,
          track: 2,
          airline: "Copa Airlines",
          flightState: "En Progreso"
        },
        timePlace: {
          leavingHour: "11:00",
          arrivingHour: "14:20",
          leavingDate: "22/11/2024",
          arrivingDate: "22/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Managua, Nicaragua"
        }
      },
      {
        id: 1,
        flightName: "AV-3512",
        airport: {
          gate: 7,
          track: 4,
          airline: "Avianca",
          flightState: "Despegado"
        },
        timePlace: {
          leavingHour: "15:30",
          arrivingHour: "19:00",
          leavingDate: "22/11/2024",
          arrivingDate: "22/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Medellín, Colombia"
        }
      },
      {
        id: 2,
        flightName: "UA-9918",
        airport: {
          gate: 4,
          track: 3,
          airline: "United Airlines",
          flightState: "Atrasado"
        },
        timePlace: {
          leavingHour: "9:00",
          arrivingHour: "13:15",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Chicago, Estados Unidos"
        }
      },
      {
        id: 3,
        flightName: "DL-5503",
        airport: {
          gate: 8,
          track: 5,
          airline: "Delta Airlines",
          flightState: "Despegado"
        },
        timePlace: {
          leavingHour: "7:00",
          arrivingHour: "12:30",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Nueva York, Estados Unidos"
        }
      },
      {
        id: 4,
        flightName: "AA-4332",
        airport: {
          gate: 9,
          track: 7,
          airline: "American Airlines",
          flightState: "En Progreso"
        },
        timePlace: {
          leavingHour: "12:00",
          arrivingHour: "15:45",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Miami, Estados Unidos"
        }
      },
      {
        id: 5,
        flightName: "IB-2201",
        airport: {
          gate: 1,
          track: 6,
          airline: "Iberia",
          flightState: "Programado"
        },
        timePlace: {
          leavingHour: "14:30",
          arrivingHour: "5:00",
          leavingDate: "23/11/2024",
          arrivingDate: "24/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Madrid, España"
        }
      },
      {
        id: 6,
        flightName: "G3-3120",
        airport: {
          gate: 3,
          track: 8,
          airline: "Gol Linhas Aéreas",
          flightState: "En Progreso"
        },
        timePlace: {
          leavingHour: "16:00",
          arrivingHour: "22:30",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Río de Janeiro, Brasil"
        }
      },
      {
        id: 7,
        flightName: "LH-4156",
        airport: {
          gate: 2,
          track: 1,
          airline: "Lufthansa",
          flightState: "Programado"
        },
        timePlace: {
          leavingHour: "18:30",
          arrivingHour: "6:00",
          leavingDate: "23/11/2024",
          arrivingDate: "24/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Frankfurt, Alemania"
        }
      },
      {
        id: 8,
        flightName: "KLM-4432",
        airport: {
          gate: 5,
          track: 4,
          airline: "KLM",
          flightState: "Atrasado"
        },
        timePlace: {
          leavingHour: "13:15",
          arrivingHour: "4:30",
          leavingDate: "23/11/2024",
          arrivingDate: "24/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Ámsterdam, Países Bajos"
        }
      },
      {
        id: 9,
        flightName: "CM-5784",
        airport: {
          gate: 10,
          track: 2,
          airline: "Copa Airlines",
          flightState: "Despegado"
        },
        timePlace: {
          leavingHour: "8:30",
          arrivingHour: "10:45",
          leavingDate: "23/11/2024",
          arrivingDate: "23/11/2024",
          origin: "San Jose, Costa Rica",
          destiny: "Ciudad de Panamá, Panamá"
        }
      }
    ]
  };
   
   
  const selectorData = [
    {name: 'Llegadas', id: 0},
    {name: 'Salidas', id: 1}
  ]

  const { fireNotification } = useNotifications();
  const [items, setItems] = useState(flightsData.comming)
  const [entrees, setEntrees] = useState(null)                         //entree se refiere a cada registro en la tabla
  const [isComming, toggleIsComming] = useToggleState(true)
  const [isEditMode, toggleIsEditMode] = useToggleState(false)
  const [showAddPopUp, toggleShowAddPopUp] = useToggleState(false)
  const [showAddEditPopUp, setShowAddEditPopUp] = useState({})
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: flightsData.comming.length,
    selectedIndex: 1
  });

  const toggleShowAddEditPopUp = id => {
    setShowAddEditPopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const changeTableControllerIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderTable = (items, tableController) => {
    if(!items) return
    renderEntrees(items, tableController)
    renderPaginateSelector(items, tableController)
  }
  const renderEntrees = (data, tableController) => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage

    setEntrees(
      data.slice(startIndex, endIndex)
      .filter(item => item)
      .map(item => {
        const { id, flightName } = item

        return (
          <div key={id} className="entree">
            <span>{ flightName }</span>
            <span
              className='clickable'
              onClick={() => toggleShowAddEditPopUp(id)}
            >{isEditMode ? 'Editar' : 'Ver'} Datos Vuelo</span>
            <PopUp
              isVisible={showAddEditPopUp[id]}
              toggleIsVisible={() => toggleShowAddEditPopUp(id)}
              content={showAddEditPopUp[id] && (
                <ViewModifyFlight 
                  isEditMode={isEditMode}
                  dataLabels={isComming ? flightsLabels.comming : flightsLabels.leaving} 
                  data={item} 
                />
              )}
            />
            {isEditMode && <img src={trashSvg} />}
          </div>
        )
      })
    )
  }
  const renderPaginateSelector = (data, tableController) => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => handleIndexChange(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => handleIndexChange(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => handleIndexChange(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const handleIndexChange = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeTableControllerIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeTableControllerIndex(currentIndex - 1)
      return
    }

    changeTableControllerIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Vuelos"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderTable(items, tableController)
  }, [tableController, items, showAddPopUp, isEditMode, showAddEditPopUp])
  useEffect(() => {
    let newItems = isComming ? flightsData.comming : flightsData.leaving
    setItems(newItems)
    setTableController(prevState => (
      {...prevState, dataItems: newItems.length}
    ))
  }, [isComming])

  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      <Selector
        options={selectorData}
        getSelectedIndex={index => toggleIsComming(index)}
      />
      <div className="table">
        <div className="titles">
          <span>Vuelo</span>
          <span>Datos Vuelo</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">

          {isEditMode && <>
            <AddElement 
              itemTitle={'Ocupación'} 
              onClick={toggleShowAddPopUp}
            />
            <PopUp
              isVisible={showAddPopUp}
              toggleIsVisible={toggleShowAddPopUp}
              content={showAddPopUp && (
                <ViewModifyFlight 
                  isEditMode={true}
                  dataLabels={isComming ? flightsLabels.comming : flightsLabels.leaving} 
                />
              )}
            />
          </>}
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default GestionGatesPistas;