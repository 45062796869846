import { useEffect, useState } from "react";

const ManageBaggage = ({data, dataLabels, isEditMode}) => {
  const [selectedData, setSelectedData] = useState(data ? data : null)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels)

  return (

    <div className="datos-empleado" style={{marginTop: '150px'}}>
      <div className="baggage-wrapper">
        <div className="titles-maletas">
          <span>Peso</span>
          <span>Tamaño</span>
          <span>Color</span>
          <span>Estado</span>
        </div>
        {selectedData.map((bag, index) => (
          <div key={index} className="datos-maleta">
              {!isEditMode
                ? <span>{ bag.weight }</span>
                : <input placeholder={ bag.weight } type="text" />
              }
              {!isEditMode
                ? <span>{ bag.size }</span>
                : <input placeholder={ bag.size } type="text" />
              }
              {!isEditMode
                ? <span>{ bag.color }</span>
                : <input placeholder={ bag.color } type="text" />
              }
              {!isEditMode
                ? <span>{ bag.state }</span>
                : <input placeholder={ bag.state } type="text" />
              }
            </div>
        ))}
      </div>
      <button style={{position: 'absolute', bottom: '30px', right: '60px'}}>Agregar Maleta</button>
    </div>

  );
}
 
export default ManageBaggage;