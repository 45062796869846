import { useState } from "react"

const useToggleState = initialValue => {
  const [state, setState] = useState(initialValue)

  const toggleState = () => {
    setState((state) => !state)
  }

  return [state, toggleState]
}

export default useToggleState;