const AgregarEmpleado = () => {
  const data = {
    resourceId: "",
    ocupationRange: '',
    ocupiedBy: ''
  }
  const labels = {
    resourceId: "Numero",
    ocupationRange: 'Rango de Ocupación',
    ocupiedBy: 'Ocupado por'
  }

  return (
    <div className="datos-empleado">
      <div className="data-table">
        {Object.keys(labels).map((att, index) => (
          <div key={index} style={{marginBottom: '30px'}}>
            <span 
              style={{fontWeight: 700, marginRight: '20px'}}
            >{ labels[att] }:</span>
            <input 
              type="text"
              placeholder={data[att]}
            />
          </div>
        ))}
      </div>    
    </div> 
  );
}
 
export default AgregarEmpleado;