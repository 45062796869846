import { useEffect, useState } from 'react';
import '../../../styles/administration/gestion-roles.css'
import trashSvg from '../../../assets/trash.svg'

import useToggleState from '../../hooks/useToggleState'
import PopUp from '../../reusable/PopUp'
import AddElement from '../../reusable/AddElement';
import ManagePassenger from './ManagePassenger';
import ManageBaggage from './ManageBaggage';
import useNotifications from '../../hooks/useNotifications';

const GestionGatesPistas = () => {
  const passengerLabels = {
    personal: {
      name: "Nombre",
      passport: "Pasaporte",
      contact: "Contacto",
      flight: "Vuelo"
    },
    baggage: [
      {
        weight: 'Peso',
        size: "Tamaño",
        color: "Color",
        state: "Estado"
      }
    ]
  }
  const passengerData = [
    {
      id: 1,
      personal: {
        name: "Maria Gonzalez",
        passport: "PA1234567",
        contact: 88991122,
        flight: "AB-100"
      },
      baggage: [
        { id: 0, weight: 12, size: "Grande", color: "Azul", state: "Bordado" },
        { id: 1, weight: 7, size: "Mediano", color: "Verde", state: "Extraviado" }
      ]
    },
    {
      id: 2,
      personal: {
        name: "Juan Perez",
        passport: "PA7654321",
        contact: 88223344,
        flight: "CD-222"
      },
      baggage: [
        { id: 0, weight: 10, size: "Pequeño", color: "Negro", state: "Normal" }
      ]
    },
    {
      id: 3,
      personal: {
        name: "Ana Ramirez",
        passport: "PA0987654",
        contact: 88445566,
        flight: "EF-333"
      },
      baggage: [
        { id: 0, weight: 15, size: "Grande", color: "Rojo", state: "Normal" },
        { id: 1, weight: 5, size: "Pequeño", color: "Gris", state: "Extraviado" },
        { id: 2, weight: 9, size: "Mediano", color: "Amarillo", state: "Normal" }
      ]
    },
    {
      id: 4,
      personal: {
        name: "Carlos Torres",
        passport: "PA8765432",
        contact: 88776655,
        flight: "GH-444"
      },
      baggage: [
        { id: 0, weight: 18, size: "Grande", color: "Negro", state: "Extraviado" },
        { id: 1, weight: 11, size: "Mediano", color: "Azul", state: "Normal" }
      ]
    },
    {
      id: 5,
      personal: {
        name: "Laura Jimenez",
        passport: "PA2345678",
        contact: 88112233,
        flight: "IJ-555"
      },
      baggage: [
        { id: 0, weight: 14, size: "Grande", color: "Rosa", state: "Bordado" }
      ]
    },
    {
      id: 6,
      personal: {
        name: "Pedro Salazar",
        passport: "PA3456789",
        contact: 88001122,
        flight: "KL-666"
      },
      baggage: [
        { id: 0, weight: 6, size: "Pequeño", color: "Verde", state: "Normal" },
        { id: 1, weight: 9, size: "Mediano", color: "Blanco", state: "Extraviado" }
      ]
    },
    {
      id: 7,
      personal: {
        name: "Sofia Rojas",
        passport: "PA4567890",
        contact: 88221133,
        flight: "MN-777"
      },
      baggage: [
        { id: 0, weight: 7, size: "Mediano", color: "Azul", state: "Bordado" },
        { id: 1, weight: 8, size: "Pequeño", color: "Negro", state: "Normal" },
        { id: 2, weight: 16, size: "Grande", color: "Rojo", state: "Extraviado" }
      ]
    },
    {
      id: 8,
      personal: {
        name: "Luis Vargas",
        passport: "PA5678901",
        contact: 88334455,
        flight: "OP-888"
      },
      baggage: [
        { id: 0, weight: 13, size: "Grande", color: "Marrón", state: "Normal" }
      ]
    },
    {
      id: 9,
      personal: {
        name: "Isabella Navarro",
        passport: "PA6789012",
        contact: 88990011,
        flight: "QR-999"
      },
      baggage: [
        { id: 0, weight: 10, size: "Mediano", color: "Rosa", state: "Extraviado" },
        { id: 1, weight: 4, size: "Pequeño", color: "Verde", state: "Normal" }
      ]
    },
    {
      id: 10,
      personal: {
        name: "Diego Castillo",
        passport: "PA7890123",
        contact: 88778899,
        flight: "ST-111"
      },
      baggage: [
        { id: 0, weight: 12, size: "Grande", color: "Negro", state: "Normal" },
        { id: 1, weight: 5, size: "Pequeño", color: "Blanco", state: "Bordado" }
      ]
    }
  ];
  
  
  const { fireNotification } = useNotifications();
  const [items, setItems] = useState(passengerData)
  const [entrees, setEntrees] = useState(null)                         //entree se refiere a cada registro en la tabla
  const [isEditMode, toggleIsEditMode] = useToggleState(false)
  const [showAddPopUp, toggleShowAddPopUp] = useToggleState(false)
  const [showAddEditPopUp, setShowAddEditPopUp] = useState({})
  const [showBaggagePopUp, setShowBaggagePopUp] = useState({})
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: passengerData.length,
    selectedIndex: 1
  });

  const toggleShowAddEditPopUp = id => {
    setShowAddEditPopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const toggleShowBaggagePopUp = id => {
    setShowBaggagePopUp(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const changeTableControllerIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderTable = (items, tableController) => {
    if(!items) return
    renderEntrees(items, tableController)
    renderPaginateSelector(items, tableController)
  }
  const renderEntrees = (data, tableController) => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage

    setEntrees(
      data.slice(startIndex, endIndex)
      .filter(item => item)
      .map(item => {
        //item es todo el objeto
        const { id, personal } = item

        return (
          <div key={id} className="entree">
            <span>{ personal.name }</span>
            <span
              className='clickable'
              onClick={() => toggleShowAddEditPopUp(id)}
            >{isEditMode ? 'Editar' : 'Ver'} Datos Personales</span>
            <PopUp
              isVisible={showAddEditPopUp[id]}
              toggleIsVisible={() => toggleShowAddEditPopUp(id)}
              content={showAddEditPopUp[id] && (
                <ManagePassenger 
                  isEditMode={isEditMode}
                  dataLabels={passengerLabels.personal} 
                  data={item.personal} 
                />
              )}
            />
            <span
              className='clickable'
              onClick={() => toggleShowBaggagePopUp(id)}
            >{isEditMode ? 'Editar' : 'Ver'} Equipage</span>
            <PopUp
              isVisible={showBaggagePopUp[id]}
              toggleIsVisible={() => toggleShowBaggagePopUp(id)}
              content={showBaggagePopUp[id] && (
                <ManageBaggage 
                  isEditMode={isEditMode}
                  dataLabels={passengerLabels.baggage} 
                  data={item.baggage} 
                />
              )}
            />
            {isEditMode && <img src={trashSvg} />}
          </div>
        )
      })
    )
  }
  const renderPaginateSelector = (data, tableController) => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => handleIndexChange(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => handleIndexChange(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => handleIndexChange(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const handleIndexChange = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeTableControllerIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeTableControllerIndex(currentIndex - 1)
      return
    }

    changeTableControllerIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Pasajeros"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderTable(items, tableController)
  }, [tableController, items, showAddPopUp, isEditMode, showAddEditPopUp, showBaggagePopUp])


  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      <div className="table">
        <div className="titles">
          <span>Pasagero</span>
          <span>Datos Personales</span>
          <span>Datos Maletas</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">

          {isEditMode && <>
            <AddElement 
              itemTitle={'Pasagero'} 
              onClick={toggleShowAddPopUp}
            />
            <PopUp
              isVisible={showAddPopUp}
              toggleIsVisible={toggleShowAddPopUp}
              content={showAddPopUp && (
                <ManagePassenger 
                  isEditMode={true}
                  dataLabels={passengerLabels.personal} 
                />
              )}
            />
          </>}
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default GestionGatesPistas;