import { useEffect, useState } from 'react';
import '../../../styles/coordination/map.css'
import trashSvg from '../../../assets/trash.svg'

import useToggleState from '../../hooks/useToggleState'
import PopUp from '../../reusable/PopUp'
import AddElement from '../../reusable/AddElement';
import Selector from '../../reusable/Selector';
import ViewResource from './ViewResource';
import GatesMap from './GatesMap';
import TracksMap from './TracksMap';

const Map = () => {
  const gates = [
    {
      id: 1,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "American Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Delta Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 2,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Libre", state: "#80FF8E" }, // Libre
        { occupationRange: "10:30 - 10:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "JetBlue", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 3,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Libre", state: "#80FF8E" }, // Libre
        { occupationRange: "10:30 - 10:55", occupiedBy: "Southwest Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 4,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "United Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Delta Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 5,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Spirit Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "JetBlue", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 6,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" }, // Bajo Mantenimiento
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Southwest Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
      ],
    },
    {
      id: 7,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Delta Airlines", state: "#FF80E8" }, // Desocupando
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "United Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 8,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Southwest Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "JetBlue", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 9,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "United Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Delta Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
    {
      id: 10,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "American Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Southwest Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
      ],
    },
    {
      id: 11,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Libre", state: "#80FF8E" }, // Libre
        { occupationRange: "10:30 - 10:55", occupiedBy: "JetBlue", state: "#8082FF" }, // Asignado
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" },
      ],
    },
  ];
  const tracks = [
    {
      id: 1,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Delta Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "American Airlines", state: "#8082FF" } // Asignado
      ]
    },
    {
      id: 2,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" }, // Bajo Mantenimiento
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "United Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" }
      ]
    },
    {
      id: 3,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Southwest Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "American Airlines", state: "#8082FF" } // Asignado
      ]
    },
    {
      id: 4,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Delta Airlines", state: "#FF80E8" }, // Desocupando
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "United Airlines", state: "#8082FF" } // Asignado
      ]
    },
    {
      id: 5,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Libre", state: "#80FF8E" }, // Libre
        { occupationRange: "10:30 - 10:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Delta Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" }
      ]
    },
    {
      id: 6,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "Libre", state: "#80FF8E" }, // Libre
        { occupationRange: "10:30 - 10:55", occupiedBy: "United Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:00 - 11:25", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Libre", state: "#80FF8E" }
      ]
    },
    {
      id: 7,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "American Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" },
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Delta Airlines", state: "#8082FF" } // Asignado
      ]
    },
    {
      id: 8,
      schedule: [
        { occupationRange: "10:00 - 10:25", occupiedBy: "United Airlines", state: "#FF8080" }, // Ocupado
        { occupationRange: "10:30 - 10:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "11:00 - 11:25", occupiedBy: "Delta Airlines", state: "#8082FF" }, // Asignado
        { occupationRange: "11:30 - 11:55", occupiedBy: "Libre", state: "#80FF8E" },
        { occupationRange: "12:00 - 12:25", occupiedBy: "Bajo Mantenimiento", state: "#F0FF80" }
      ]
    }
  ];
  const selectorOptions = [
    {name: 'Puertos', id: 0},
    {name: 'Pistas', id: 1}
  ]

  const [isGates, setIsGates] = useState(true);                                           // opcion seleccionada con el selector superior
  const [selectedMapData, setSelectedMapData] = useState(gates)                           // datos de ya sea gates o pistas
  const [selectedResourceData, setSelectedResourceData] = useState(null)                  // datos del recurso elegido (un gate o pista especifico)
  const [showResourcePopUp, toggleShowResourcePopUp] = useToggleState(false)              // toggles cuando toco los recursos

  const handleSelector = id => {
    let isGatesLocal = id === 0 ? true : false;
    
    setIsGates(isGatesLocal)
    setSelectedMapData(isGatesLocal ? gates : tracks)
  }
  const handleMapClick = id => {
    setSelectedResourceData(selectedMapData[id])
    toggleShowResourcePopUp()
  }

  return (
    <div className="map">
      <Selector
        options={selectorOptions}
        getSelectedIndex={index => handleSelector(index)}
      />
      <div className="maps">
        { isGates && <GatesMap
          resource={selectedMapData}
          handleMapClick={index => handleMapClick(index)}
        /> }
        { !isGates && <TracksMap
          resource={selectedMapData}
          handleMapClick={index => handleMapClick(index)}
        /> }
      </div>
      {showResourcePopUp && 
        <PopUp
          isVisible={showResourcePopUp}
          toggleIsVisible={toggleShowResourcePopUp}
          content={showResourcePopUp && (
            <ViewResource
              data={selectedResourceData}
            />
          )}
        />
      }
    </div>
  );
}
 
export default Map;