import { useEffect, useState } from 'react';
import '../../styles/administration/gestion-roles.css'
import trashSvg from '../../assets/trash.svg'

import useToggleState from '../hooks/useToggleState'
import PopUp from '../reusable/PopUp'
import AddUser from './AddUser';
import AddElement from '../reusable/AddElement';
import Select from '../reusable/Select';
import useNotifications from '../hooks/useNotifications';

const GestionRoles = () => {
  const usersData = [
    {
      username: 'miguelvillalta2',
      name: 'Miguel Villalta',
      rol: 1,
      id: 0
    },
    {
      username: 'juanperez',
      name: 'Juan Pérez',
      rol: 0,
      id: 1
    },
    {
      username: 'ana_gonzalez',
      name: 'Ana González',
      rol: 1,
      id: 2
    },
    {
      username: 'carlos_ramirez',
      name: 'Carlos Ramírez',
      rol: 3,
      id: 3
    },
    {
      username: 'maria_lopez',
      name: 'María López',
      rol: 2,
      id: 4
    },
    {
      username: 'pedro_castillo',
      name: 'Pedro Castillo',
      rol: 1,
      id: 5
    },
    {
      username: 'luisa_martinez',
      name: 'Luisa Martínez',
      rol: 3,
      id: 6
    },
    {
      username: 'jose_rodriguez',
      name: 'José Rodríguez',
      rol: 2,
      id: 7
    },
    {
      username: 'claudia_diaz',
      name: 'Claudia Díaz',
      rol: 0,
      id: 8
    },
    {
      username: 'sandra_torres',
      name: 'Sandra Torres',
      rol: 3,
      id: 9
    }
  ];
  const selectData = [
    {name: "No Autorizado", id: 0},
    {name: "Administrador", id: 1},
    {name: "Coordinador", id: 2},
    {name: "Recursos Humanos", id: 3}
  ]  



  const { fireNotification } = useNotifications();
  const [items, setItems] = useState(usersData)
  const [entrees, setEntrees] = useState(null)                                      //entree se refiere a cada registro en la tabla
  const [showAddUser, toggleShowAddUser] = useToggleState(false)
  const [isEditMode, toggleIsEditMode] = useToggleState(false)
  const [paginateSelector, setPaginateSelector] = useState(null)
  const [tableController, setTableController] = useState({
    elementsPerPage: 5,
    dataItems: usersData.length,
    selectedIndex: 1
  });

  const getRol = rolId => {
    switch(rolId){
      case 0: return "No Autorizado"
      case 1: return "Administrador"
      case 2: return "Coordinador"
      case 3: return "Recursos Humanos"
    }
  }
  const changeSelectedIndex = index => {
    setTableController(prevState => (
      {...prevState, selectedIndex: index}
    ))
  }
  const renderTable = items => {
    if(!items) return
    renderEntrees(items)
    renderPaginateSelector(items)
  }
  const renderEntrees = data => {
    const elPerPage = tableController.elementsPerPage
    const selIndex = tableController.selectedIndex
    const endIndex = elPerPage * selIndex
    const startIndex = endIndex - elPerPage
    let elementArray = [];

    if(isEditMode){
      elementArray.push(<>
        <AddElement 
          itemTitle={"Empleado"} 
          onClick={toggleShowAddUser}
        />
        <PopUp
            isVisible={showAddUser}
            toggleIsVisible={toggleShowAddUser}
            content={showAddUser && (
              <AddUser />
            )}
          />
      </>)
    }

    for(let i = startIndex; i < endIndex; i++){
      if(!data[i]) continue

      let item = data[i]
      let id = item.id
      let element = (
        <div key={id} className="entree">
          <span>{ item.username }</span>
          <span>{ item.name }</span>

          <span>
            {isEditMode 
              ? <Select 
                head={selectData[item.rol]} 
                selectOptions={selectData} 
                handleChange={change => console.log(change)}
              /> 
              : getRol(item.rol)
            } 
          </span>

          {isEditMode && <img src={trashSvg} />}
        </div>
      )
      elementArray.push(element)
    }

    setEntrees(elementArray)
  }
  const renderPaginateSelector = data => {
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = data ? data.length : tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)
    let selectorElements = []
    
    selectorElements.push(
      <span 
        key={0} 
        style={{marginRight: '30px'}}
        onClick={() => changeIndex(0)}
      >&lt;</span>
    )

    for(let i = 0; i < pagesNumber; i++){
      let selectedIndex = tableController.selectedIndex
      let index = i + 1
      selectorElements.push(
        <span 
          key={index} 
          style={{margin: '0px 10px'}}
          onClick={() => changeIndex(index)}
          className={index == selectedIndex ? 'selected' : ''}
        >{ index }</span>
      )
    }
  
    selectorElements.push(
      <span 
        key={pagesNumber + 1} 
        style={{marginLeft: '30px'}}
        onClick={() => changeIndex(pagesNumber + 1)}
      >&gt;</span>
    )

    setPaginateSelector(selectorElements)
  }
  const changeIndex = direction => {
    const currentIndex = tableController.selectedIndex
    const elementsPerPage = tableController.elementsPerPage
    const dataItems = tableController.dataItems
    let pagesNumber = Math.ceil(dataItems / elementsPerPage)

    //arrow commands
    if(direction == pagesNumber + 1){
      if(currentIndex >= pagesNumber){
        return
      }
      changeSelectedIndex(currentIndex + 1)
      return
    }
    if(direction == 0){
      if(currentIndex <= 1 ){
        return
      }
      changeSelectedIndex(currentIndex - 1)
      return
    }

    changeSelectedIndex(direction)
  }
  const runChangesNotification = () => {
    const title = 'Cambios Detectados'
    const body = 'Se detectaron cambios en el módulo "Gestión de Roles"'

    fireNotification(title, body)
  }

  useEffect(() => {
    renderTable(items)
  }, [tableController, items, showAddUser, isEditMode])

  useEffect(() => {
    setTableController(prevState => (
      {...prevState, dataItems: items.length}
    ))
  }, [items])

  return (
    <div className="gestion-empleados">
      <div className="gestion-btns">
        {isEditMode && 
          <div style={{display: 'flex', gap: '20px'}}>
            <button
              onClick={() => {
                toggleIsEditMode()
                runChangesNotification()
              }}
            >Guardar</button>
            <button
              onClick={toggleIsEditMode}
            >Cancelar</button>
          </div>
        }
        {!isEditMode && 
          <button
            onClick={toggleIsEditMode}
          >Gestionar</button>
        }
      </div>
      <div className="table">
        <div className="titles">
          <span>Usuario</span>
          <span
          >Nombre</span>
          <span>Rol</span>
          {isEditMode && 
            <span>Gestionar</span>
          }
        </div>
        <div className="entrees">
          {entrees}
        </div>
        <div className="index-selector">
          {paginateSelector}
        </div>
      </div>
    </div>
  );
}
 
export default GestionRoles;