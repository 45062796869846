import { useEffect, useState } from "react";

const ViewResource = ({ data }) => {
  return (
    <div className="occupations">
      <h2>Puerto { data.id }</h2>
      <div className="occupation-table">
        <div className="col">
          <b>Rango de Ocupaci&oacute;n:</b>
          {data.schedule.map((occupation, index) => (
            <span key={index}>{ occupation.occupationRange }</span>
          ))}
        </div>
        <div className="col">
          <b>Ocupado Por:</b>
          {data.schedule.map((occupation, index) => (
            <div key={index} className="occupied-by-row">
              <span key={index}>{ occupation.occupiedBy }</span>
              <div className="indicator" style={{backgroundColor: occupation.state}}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
 
export default ViewResource;