import plusSvg from '../../assets/plus.svg'

const AddElement = ({itemTitle, onClick}) => {
  return (
    <div 
      className="add-element"
      onClick={onClick}  
    >
      <span>Agregar {itemTitle}</span>
      <img src={plusSvg} alt="" />
    </div>
  );
}
 
export default AddElement;