import { useEffect, useState } from "react";
import Selector from "../reusable/Selector";

const DatosNomina = ({data, dataLabels, isEditMode}) => {
  const selectorOptions = [
    {
      name: 'Datos',
      id: 0
    },
    {
      name: 'Calcular',
      id: 1
    }
  ]
  const calcDataLabels = {
    baseSalary: "Salario Base",
    extraHours: "Horas Extras Trabajadas",
    extraPay: "Pago Extra",
    total: "Total"
  }
  const [selectedData, setSelectedData] = useState(data)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels)
  const [populatedElement, setPopulatedElement] = useState(<></>)
  const [calcData, setCalcData] = useState(null)
  //no ocupo la func de  cambiar de data[personal] a data[job]
  // PERO si ocupo pasar de ver datos nomina a calcular nomina
  const calcExtraHours = () => {
    let baseSalary = Number(data.salary)
    let extraHours = Number(data.extraHours)
    let extraPay = Number(data.extraHours * data.payPerHour * 1.5)
    let total = Number(baseSalary + extraPay)

    let calc = {
      baseSalary: baseSalary,
      extraHours: extraHours,
      extraPay: extraPay,
      total: total,
    }
    setCalcData(calc)
  }
  const changeSelectedData = index => {
    switch(index){
      case 0:
        setSelectedData(data)
        setSelectedLabels(dataLabels)
        break;
      case 1:
        //calculos
        setSelectedData(calcData)
        setSelectedLabels(calcDataLabels)



        break;
    }
  }
  const updateRenderedData = () => {
    let elementArr = []
    
    for(let att in selectedData){
      let element = (
        <div style={{marginBottom: '30px'}}>
          <span style={{fontWeight: 700, marginRight: '20px'}}>{ selectedLabels[att] }:</span>
          {!isEditMode
            ? <span>{ selectedData[att] }</span>
            : <input 
                type="text"
                placeholder={selectedData[att]}
              />
          }
        </div>
      )
      elementArr.push(element)
    }

    setPopulatedElement(
      <div className="data-table">
        {elementArr.map(element => element)}
      </div>
    )
  }

  useEffect(() => {
    updateRenderedData()
  }, [selectedData])
  useEffect(calcExtraHours, [])

  return (
    <div className="datos-empleado">
      <Selector options={selectorOptions} getSelectedIndex={index => changeSelectedData(index)} />
      {selectedData && populatedElement}
    </div> 
  );
}
 
export default DatosNomina;