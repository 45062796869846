import { useEffect, useState } from "react";

const EditGatesTracks = ({data, dataLabels, isEditMode}) => {
  const [selectedData, setSelectedData] = useState(data)
  const [selectedLabels, setSelectedLabels] = useState(dataLabels)

  return (
    <div className="datos-empleado">
      <div className="data-table">
        {Object.keys(dataLabels).map((att, index) => (
          <div key={index} style={{marginBottom: '30px'}}>
            <span 
              style={{fontWeight: 700, marginRight: '20px'}}
            >{ selectedLabels[att] }:</span>
            {!isEditMode
              ? <span>{ selectedData[att] }</span>
              : <input placeholder={selectedData[att]} type="text" />
            }
          </div>
        ))}
      </div>
    </div> 
  );
}
 
export default EditGatesTracks;